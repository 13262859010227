<template>
    <main class="tl pt-35 pb-70">
        <h1 class="ph5 f-34 lh-title">患者信息</h1>

        <div class="ph5 mt-45">
            <div class="f5 b">真实姓名</div>
            <div class="pv5 bb b--light-gray">
                <input
                    type="text"
                    v-model="patient_name"
                    class="w-100 bn f5 lh-solid pl-0"
                    placeholder="填写患者真实姓名"
                    maxlength="30"
                />
            </div>
        </div>

        <div class="ph5 mt5">
            <div class="flex items-center">
                <div class="f5 b">证件号码</div>
                <div class="ml5">
                    <van-radio-group
                        v-model="aided_id_type"
                        direction="horizontal"
                    >
                        <van-radio :name="1">身份证</van-radio>
                        <van-radio :name="2">出生证</van-radio>
                    </van-radio-group>
                </div>
            </div>
            <div class="pv5 bb b--light-gray">
                <input
                    v-if="aided_id_type === 1"
                    type="text"
                    v-model.trim="card_no"
                    placeholder="请填写患者身份证号"
                    class="w-100 bn f5 lh-solid pl-0"
                    maxlength="30"
                />
                <input
                    v-else
                    type="text"
                    v-model.trim="card_no"
                    placeholder="请填写患者出生证号"
                    class="w-100 bn f5 lh-solid pl-0"
                    maxlength="30"
                />
            </div>
        </div>

        <div class="ph5 mt5">
            <div class="f5 b">所患疾病</div>
            <div class="pv5 bb b--light-gray">
                <input
                    v-position
                    v-model="disease"
                    type="text"
                    class="w-100 bn f5 lh-solid pl-0"
                    placeholder="填写确诊疾病名称"
                    maxlength="30"
                />
            </div>
        </div>

        <div class="ph5 mt5">
            <div class="flex items-center">
                <div class="f5 b">就诊医院</div>
                <div class="ml-40">
                    <AddressPicker
                        :province="hospital_loc.province"
                        :city="hospital_loc.city"
                        class="mt-12"
                        @change="onAddressChange"
                    />
                </div>
            </div>
            <div class="pv5 bb b--light-gray">
                <HospitalPicker
                    :value="hospital_loc.name"
                    :province="hospital_loc.province"
                    @change="onHospitalChange"
                />
            </div>
        </div>

        <div class="ph5 mt5">
            <div class="f5 b">上传患者{{ cardImgLabel }}照片（必填）</div>
            <div class="pv5">
                <uploader
                    :label="cardImgLabel"
                    :img="card_img[0].img"
                    class="w-31"
                    @success="onUploadImg($event, card_img[0])"
                    @delete="onDeleteImg(card_img[0])"
                />
            </div>
            <div class="silver f7">
                {{ cardImgLabel }}上的所有信息必须清晰可见，必须能看清证件号
            </div>
        </div>

        <div class="ph5 mt5">
            <div class="f5 b">医疗证明（至少提供两种）</div>
            <div class="pv5 flex justify-between flex-wrap">
                <uploader
                    v-for="(item, index) of certificates_img"
                    :key="item.type"
                    :label="item.label"
                    :img="item.img"
                    class="w-31"
                    :class="{ mt4: index > 2 }"
                    @success="onUploadImg($event, certificates_img[index])"
                    @delete="onDeleteImg(certificates_img[index])"
                />
            </div>
            <div class="silver f7">
                请<span class="b">至少</span
                >提供诊断证明、收费票据、病历、检查报告、出入院证明等中的<span
                    class="b"
                    >两种</span
                >，上传的证明越齐全，通过的概率越大！
            </div>
            <div class="f-13 moon-gray tc mt7">以上所有信息仅用于审核</div>
        </div>

        <div class="fixed bottom-0 left-0 right-0">
            <mmcButton class="b" @click="onSubmit">
                保存
            </mmcButton>
        </div>
    </main>
</template>

<script>
// @ts-check
import store from 'store';
import { RadioGroup, Radio } from 'vant';
import AddressPicker from '../common/components/AddressPicker/index.vue';
import HospitalPicker from '../common/components/HospitalPicker/index.vue';
import Uploader from '../common/components/Uploader/index.vue';
import mmcButton from '@/components/mmcUI/mmc-btn-big.vue';
import utils from '@/common/js/utils.js';
import {
    debounce,
    registerDirectives,
    replacetStrTrimAll
} from '../common/js/utils.js';

import { submitAidedInfo, getAidedInfo } from '../common/js/api.js';
import { cacheKey } from '../common/js/config.js';
import { validate, getFirstErrMsg } from '../common/js/validator.js';
import validateRules from './js/validateRules.js';
import { authChecker, shareCtrl } from '@/common/js/mmcModule';

/** 注册键盘定位指令 */
registerDirectives();

/** @typedef {{ projuuid: string, edit: string }} AidedParams 患者验证页面参数 */
const params = /** @type {AidedParams} */ (utils.getRequestParams());

/** @type {Record<string, mmc.review.aided.ID_IMG_TYPE>} 身份证图片类型 */
const idImgTypes = {
    1: 'id_card',
    2: 'birthday_card',
};

/**
 * @type {Array<{ type: mmc.review.aided.CERT_IMG_TYPE, label: string }>} 诊断证明图片类型
 * */
const certTypes = [
    {
        type: 'zhenduan',
        label: '诊断证明',
    },
    {
        type: 'shoufei',
        label: '收费票据',
    },
    {
        type: 'bingli',
        label: '病历',
    },
    {
        type: 'jiancha',
        label: '检查报告',
    },
    {
        type: 'churuyuan',
        label: '出入院证明',
    },
    {
        type: 'qita',
        label: '其他证明',
    },
];

/** 默认患者证件类型：身份证 */
const defaultIdType = 1;

export default {
    name: 'ReviewAided',

    components: {
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        AddressPicker,
        HospitalPicker,
        Uploader,
        mmcButton,
    },

    data() {
        return {
            /** 是否编辑模式 */
            isEdit: params.edit === '1',
            project_uuid: params.projuuid,
            patient_name: '',
            /** @type {mmc.review.aided.ID_TYPE} */
            aided_id_type: defaultIdType,
            card_no: '',
            disease: '',
            hospital_loc: {
                name: '',
                hospital_id: 0,
                province: 0,
                province_name: '',
                city: 0,
                city_name: '',
            },
            card_img: [
                {
                    type: idImgTypes[defaultIdType],
                    img: '',
                },
            ],
            certificates_img: certTypes.map(item => ({ ...item, img: '' })),
            /** @type {boolean} 是否正在登录 */
            isSubmitting: false,
            /** 报错信息 */
            errMsg: '',
        };
    },

    computed: {
        /**
         * 待提交的数据
         * @return {mmc.review.aided.Api}
         */
        dataToSubmit() {
            const {
                project_uuid,
                patient_name,
                aided_id_type,
                card_no,
                disease,
                hospital_loc,
                card_img,
                uploadedCertImgs,
            } = this;


            let dataToSubmitData = {
                project_uuid,
                patient_name: patient_name.trim(),
                card_no,
                aided_id_type,
                disease,
                hospital_loc,
                card_img,
                certificates_img: uploadedCertImgs,
            }

            if (dataToSubmitData.aided_id_type === 2) {
                dataToSubmitData.card_no = card_no.trim();
            } else {
                dataToSubmitData.card_no = replacetStrTrimAll(card_no);
            }
            return dataToSubmitData;
        },

        /**
         * 已经上传的证明资料图像
         * @return {Array<{ type: mmc.review.aided.CERT_IMG_TYPE, img: string }>}
         */
        uploadedCertImgs() {
            return this.certificates_img
                .filter(item => item.img)
                .map(item => {
                    const { type, img } = item;
                    return {
                        type,
                        img,
                    };
                });
        },

        cardImgLabel() {
            if (this.aided_id_type === 1) {
                return '身份证';
            }

            return '出生证';
        },
    },

    watch: {
        aided_id_type(val) {
            const idImgType = idImgTypes[val];
            if (idImgType) {
                this.card_img[0].type = idImgType;
            }
        },

        dataToSubmit: {
            deep: true,
            handler() {
                if (this.isEdit) return;

                // @ts-ignore
                this.debouncedSaveCachedData();
            },
        },
    },

    created() {
        registerDirectives();

        authChecker.check({ notneedphone: 1 }, () => {
            if (this.isEdit) {
                this.getAidedInfo();
            } else {
                this.readCacheData();
            }

            // @ts-ignore
            this.debouncedSaveCachedData = debounce(this.saveCacheData, 500);

            shareCtrl.init();
        });
    },

    methods: {
        saveCacheData() {
            store.set(cacheKey.aided, this.dataToSubmit);
        },

        /** 读取本地缓存数据 */
        readCacheData() {
            /** @type {mmc.review.aided.Api} */
            const data = store.get(cacheKey.aided);
            if (!data) return;

            /** 防止被其他项目的验证信息污染 */
            if (data.project_uuid !== this.project_uuid) return;

            this.updateLocalData(data);
        },

        /**
         * 使用外界数据（服务器或缓存）更新本地数据
         * @param {mmc.review.aided.Api} resData
         */
        updateLocalData(resData) {
            this.handleAidedInfo(resData);
        },

        async getAidedInfo() {
            const res = await getAidedInfo(this.project_uuid);
            if (res && res.code === 0 && res.data) {
                this.handleAidedInfo(res.data);
            }
        },

        /**
         * 处理服务器获取的用户信息
         * @param {mmc.review.aided.Api} resData
         */
        handleAidedInfo(resData) {
            const {
                aided_id_type,
                card_img,
                card_no,
                certificates_img,
                disease,
                hospital_loc,
                patient_name,
            } = resData;

            this.aided_id_type = aided_id_type;
            this.updateCardImg(this.card_img, card_img);
            this.updateCertImgs(this.certificates_img, certificates_img);
            this.card_no = card_no;
            this.disease = disease;
            this.hospital_loc = hospital_loc;
            this.patient_name = patient_name;
        },

        /**
         * 更新身份证件图片信息
         * @param {mmc.review.TypedImg[]} dst
         * @param {mmc.review.TypedImg[]} [src]
         */
        updateCardImg(dst, src) {
            if (src && src.length) {
                src.forEach((item, idx) => {
                    if (item && item.img && item.type) {
                        dst[idx].type = item.type;
                        dst[idx].img = item.img;
                    }
                });
            }
        },

        /**
         * 更新医疗证明图片信息
         * @param {mmc.review.TypedImg[]} dst
         * @param {mmc.review.TypedImg[]} [src]
         */
        updateCertImgs(dst, src) {
            if (!src || src.length <= 0) return;

            /** @type {Record<string, mmc.review.TypedImg>} */
            const hashTable = Object.create(null);
            dst.forEach(item => {
                hashTable[item.type] = item;
            });

            src.forEach(item => {
                const { type, img } = item || {};
                if (type && img && hashTable[type]) {
                    hashTable[type].type = type;
                    hashTable[type].img = img;
                }
            });
        },

        /**
         * 图片上传成功
         * @param {string} url 远程图片地址
         * @param {{ img: string }} item
         */
        onUploadImg(url, item) {
            if (!url) return;
            item.img = url;
        },

        /**
         * 清空图片
         * @param {{ img: string, label: string, type: string }} item
         */
        onDeleteImg(item) {
            console.log('delete', item);
            item.img = '';
        },

        /**
         * 监听地址的改变
         * @param {{ provinceId: number, cityId: number, provinceName: string, cityName: string }} e
         */
        onAddressChange(e) {
            const { provinceId, cityId, provinceName, cityName } = e;
            this.hospital_loc.province = provinceId;
            this.hospital_loc.province_name = provinceName;
            this.hospital_loc.city = cityId;
            this.hospital_loc.city_name = cityName;
        },

        /**
         * 监听医院的改变
         * @param {{id: number, name: string}} e
         */
        onHospitalChange(e) {
            this.hospital_loc.name = e.name;
            this.hospital_loc.hospital_id = Number(e.id);
        },

        /**
         * 校验用户输入是否正确
         * @returns {boolean}
         */
        checkValid() {
            const result = validate(this.dataToSubmit, validateRules);
            this.errMsg = getFirstErrMsg(result);
            return result.every(item => item.ok);
        },

        async onSubmit() {
            if (this.checkValid() === false) {
                alertMsg(this.errMsg);
                return;
            }

            if (this.isSubmitting) return;
            this.isSubmitting = true;
            showLoading('正在提交');

            try {
                const res = await submitAidedInfo(this.dataToSubmit);
                this.isSubmitting = false;
                hideLoading();

                if (res && res.code === 0) {
                    this.handleSubmit();
                } else {
                    throw new Error(res.msg || '服务器错误');
                }
            } catch (err) {
                console.log('submit error', err);
                hideLoading();
                this.isSubmitting = false;
            }
        },

        handleSubmit() {
            store.remove(cacheKey.aided);
            alertMsg('提交成功!', 1500);

            setTimeout(() => {
                history.go(-1);
            }, 1500);
        },
    },
};
</script>

<style>
@import '../common/style.css';
</style>
