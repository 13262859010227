<template>
    <div class="modal" tabindex="-1">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Modal',

    mounted() {
        document.body.classList.add('overflow-y-hidden');
    },

    beforeDestroy() {
        document.body.classList.remove('overflow-y-hidden');
    },
};
</script>

<style scoped>
.modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1024;
    background-color: rgba(0, 0, 0, 0.6);
}
</style>
