<template>
    <div class="address-picker">
        <input
            type="text"
            v-model="value"
            class="w-100 bn f5 lh-solid"
            placeholder="填写医院名称"
            readonly
            maxlength="30"
            @click="onClickButton"
        />

        <SearchModal
            v-if="showModal"
            :list="hospitalList"
            @close="showModal = false"
            @select="onSelectHospital"
        ></SearchModal>
    </div>
</template>

<script>
// @ts-check
import PickerButton from '../PickerButton/index.vue';
import SearchModal from '../SearchModal/index.vue';
import { getHospitalByProvince } from '../../js/api.js';
import { isEmptyArray } from '../../js/utils.js';

export default {
    name: 'HospitalPicker',

    components: {
        PickerButton,
        SearchModal,
    },

    props: {
        /** 当前显示的医院名称 */
        value: {
            type: String,
            default: '',
        },

        /** 省份所在的医院 Id */
        province: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            /** @type {Array.<{ id: number, name: string }>} 当前省份下的所有医院 */
            hospitalList: [],
            showModal: false,
        };
    },

    watch: {
        province(newVal) {
            if (!newVal) return;
            this.getHospitalList(newVal);
        },
    },

    mounted() {
        this.getHospitalList(this.province);
    },

    methods: {
        onClickButton() {
            if (!this.province) {
                alertMsg('请选择医院地址');
                return;
            }

            this.showModal = true;
        },

        /**
         * 获取医院列表
         * @param {number} provinceId
         */
        getHospitalList(provinceId) {
            if (!provinceId) return;

            getHospitalByProvince(provinceId)
                .then(res => {
                    // 登录过期，需要刷新页面，重新登录
                    if (res.code === 10) {
                        location.reload();
                        return;
                    }

                    if (res.data) {
                        this.getHospitalListHandler(res.data);
                    }
                })
                .catch(err => {
                    console.error(err);
                });
        },

        /**
         * 处理获取的医院列表
         * @param {{list: Array<{id: number, name: string}>}} resData
         */
        getHospitalListHandler(resData) {
            if (!resData || isEmptyArray(resData.list)) return;

            const hospitals = resData.list
                // 过滤空值
                .filter(item => item.name)
                .map(item => ({
                    id: item.id,
                    name: item.name,
                }));

            this.hospitalList = hospitals;
        },

        /**
         * 选中医院后的处理函数
         * @param {{id:string, name: string}} item
         */
        onSelectHospital(item) {
            console.log('on select item:', item);
            this.$emit('change', item);
        },
    },
};
</script>

<style scoped></style>
