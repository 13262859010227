<template>
    <div class="search-modal-hightligher">
        {{ isCustom ? '直接使用：' : '' }}{{ segments[0]
        }}<span class="blue">{{ keyword }}</span
        >{{ segments[1] }}
    </div>
</template>

<script>
export default {
    name: 'SearchModalHightlighter',

    props: {
        /** 用户输入的是否自定义（即没有在列表中） */
        isCustom: {
            type: Boolean,
            default: false,
        },

        value: {
            type: String,
            default: '',
        },

        keyword: {
            type: String,
            default: '',
        },
    },

    computed: {
        segments() {
            if (!this.value) return ['', ''];
            if (!this.keyword) return [this.value, ''];

            const index = this.value.indexOf(this.keyword);
            if (index === -1) return [this.value, ''];

            const prefix = this.value.slice(0, index);
            const suffix = this.value.slice(index + this.keyword.length);
            return [prefix, suffix];
        },
    },
};
</script>
