// @ts-check

/**
 * 判断是否身份证
 * @param {{aided_id_type: mmc.review.aided.ID_TYPE}} item
 * @returns {boolean}
 */
function isIdcard(item) {
    return item.aided_id_type === 1;
}

/**
 * 判断是否出生证
 * @param {{aided_id_type: mmc.review.aided.ID_TYPE}} item
 * @returns {boolean}
 */
function isBirthNo(item) {
    return item.aided_id_type === 2;
}

/**
 * 是否包含地址
 * @param {{province: number, city: number}} item
 * @return {boolean}
 */
function hasAddress(item) {
    return item.province > 0 && item.city > 0;
}

/**
 * 是否填写医院名称
 * @param {{name:string}} item
 * @return {boolean}
 */
function hasHospitalName(item) {
    return item.name !== '' && item.name.length >= 4;
}

/**
 * 是否上传身份证件图片
 * @param {Array<{ img: string }>} item
 * @returns {boolean}
 */
function hasCardImg(item) {
    return item[0].img !== '';
}

export default [
    {
        id: 'patient_name',
        rules: [
            { required: true, minlength: 2, msg: '请填写正确的患者真实姓名' },
        ],
    },
    {
        id: 'card_no',
        filter: isIdcard,
        rules: [
            { required: true, minlength: 6, msg: '请填写正确的患者身份证号码' },
            { regexp: /^[0-9a-z]+$/i, msg: '请填写正确的患者身份证号码' },
        ],
    },
    {
        id: 'card_no',
        filter: isBirthNo,
        rules: [
            {
                required: true,
                minlength: 6,
                regexp: /^[a-zA-Z0-9_\-\—\s*]+$/,
                msg: '请填写正确的患者出生证编号',
            },
        ],
    },
    {
        id: 'disease',
        rules: [
            {
                required: true,
                minlength: 2,
                msg: '请填写正确的所患疾病名称',
            },
        ],
    },
    {
        id: 'hospital_loc',
        rules: [
            { func: hasAddress, msg: '请选择就诊医院所在省市' },
            { func: hasHospitalName, msg: '请填写正确的就诊医院名称' },
        ],
    },
    {
        id: 'card_img',
        rules: [
            {
                filter: isIdcard,
                func: hasCardImg,
                msg: '请上传患者手持身份证照片',
            },
            {
                filter: isBirthNo,
                func: hasCardImg,
                msg: '请上传患者出生证照片',
            },
        ],
    },
    {
        id: 'certificates_img',
        rules: [
            {
                minlength: 1,
                msg: '请上传患者医疗证明照片',
            },
            {
                minlength: 2,
                msg: '请至少上传两类医疗证明图片',
            },
        ],
    },
];
