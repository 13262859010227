<template>
    <Modal class="search-modal">
        <div class="modal-content bg-white absolute">
            <div class="modal--header flex items-center">
                <div
                    class="flex-grow-1 bg-near-white br-4 pv4 ph4 flex items-center"
                >
                    <input
                        v-model.trim="userInput"
                        class="f5 flex-grow-1 bg-transparent bn lh-solid pa-0 ma-0"
                        type="text"
                        placeholder="相关医院名称 如：协和医院"
                    />
                    <div
                        v-if="userInput"
                        class="clear"
                        @click="userInput = ''"
                    ></div>
                </div>
                <div class="f5 gray ml3" @click="close">取消</div>
            </div>
            <div class="modal--body">
                <template v-if="inited">
                    <div
                        v-for="item in filteredList"
                        :key="item.id"
                        class="list-item f6 dark-gray pv5 ml4"
                        @click="onClickItem(item)"
                    >
                        <Highlighter
                            :is-custom="item.id === '0'"
                            :value="item.name"
                            :keyword="userInput"
                        />
                    </div>
                </template>
                <div v-else class="f6 dark-gray tc pv5">
                    医院列表加载中，请稍等…
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '../Modal/index.vue';
import Highlighter from '../SearchModalHighlighter/index.vue';
import { isEmptyArray } from '../../js/utils.js';

export default {
    name: 'SearchModal',

    components: {
        Modal,
        Highlighter,
    },

    props: {
        /** @type {Array<{ id: string, name: string }>} */
        list: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    data() {
        return {
            userInput: '',
            /** 是否初始化 */
            inited: false,
        };
    },

    computed: {
        /**
         * 返回匹配用户输入的列表
         * @returns {Array<{id: string, name: string}>}
         */
        filteredList() {
            const { list, userInput } = this;
            const defaultList = [{ id: '0', name: userInput }];

            if (isEmptyArray(list)) return defaultList;
            if (!userInput) return list;

            const result = list.filter(
                item => item.name.indexOf(userInput) !== -1
            );

            if (isEmptyArray(result)) {
                return defaultList;
            }

            return result;
        },
    },

    mounted() {
        // 延迟初始化，防止某些安卓机卡顿
        setTimeout(() => {
            this.inited = true;
        }, 200);
    },

    methods: {
        /**
         * 点击选中元素
         * @param {{id:string, name: string}} item
         */
        onClickItem(item) {
            this.$emit('select', item);
            this.close();
        },

        close() {
            this.$emit('close');
        },
    },
};
</script>

<style scoped>
.modal-content {
    border-radius: 8px 8px 0 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.modal--header {
    padding: 20px 16px 0;
}

.modal--body {
    height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

.clear {
    width: 16px;
    height: 16px;
    background: url('./img/clear.png') no-repeat center;
    background-size: contain;
}

.list-item {
    border-top: 1px solid #e5e5e5;
}

.list-item:first-child {
    border-top-width: 0;
}
</style>
